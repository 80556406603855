input[type=radio],
input.radio {
  margin-right: 30px;
}

.radio {
    display: inline;
}

fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 15px;
}

.getGpsButton {
    margin-top: 30px;
}

input[readOnly] {
    border:0;
    font-weight: bold;
}

.all-center {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.datafield {
    margin-bottom: 10px;
}

#location-accuracy-green {
    color: green;
}

#location-accuracy-yellow {
    color: rgb(207, 207, 0);
}

#location-accuracy-red {
    color: red;
}

.button-area {
    text-align: center;
}

.button-view {
    align-content: center;
    text-align: center;
    margin: 1px;
    font-size: 18px;
    width: 200px;
    text-transform: none;
    background-color: rgba(7,84,140,0.5) !important;
    border-color: rgba(7,84,140,0.5) !important;
}

.button-view:disabled {
    background-color: rgb(7,84,140,1) !important;
    opacity: 1;
}

#load-data-modal {
    position: fixed;
    width: 90%;
    max-height: 90%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}
